/* eslint-disable react/display-name, jsx-a11y/click-events-have-key-events */
import {Navigation} from "react-minimal-side-navigation";
import {useLocation, useNavigate} from "react-router-dom";
import {FaMountain} from "react-icons/fa";
import {FaHome} from "react-icons/fa";
import {
  GiArchiveRegister,
  GiDiploma,
  GiHamburgerMenu,
  GiMountainClimbing
} from "react-icons/gi";
//import {GrUserSettings} from "react-icons/gr";
import {BsListNested} from "react-icons/bs";
import {AiOutlineTeam} from "react-icons/ai";
import {RiTeamFill} from "react-icons/ri";
import {GrUserAdmin} from "react-icons/gr";
import {MdOutdoorGrill} from "react-icons/md"
//import  {GrMultiple} from "react-icons/gr"
import {FaPassport} from "react-icons/fa"
import React, {useState} from "react";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import Disconnect from "./Disconnect";
import CartComponent from "../Cart/CartComponent";

export const NavSidebarAdmin = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);


    return (
        <React.Fragment>
            {/* Sidebar Overlay */}
            <div
                onClick={() => setIsSidebarOpen(false)}
                className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${isSidebarOpen ? "block" : "hidden"}`}
            />


            <div>
                <button
                    className="btn-menu"
                    onClick={() => setIsSidebarOpen(true)}
                    type="button"
                >
                    <GiHamburgerMenu name="burger" className="w-10 h-6"/>
                </button>
            </div>

            <CartComponent/>

            {isSidebarOpen &&
                <div
                    className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 ease-out transform translate-x-0 bg-white border-r-2 `}
                >
                    <div className="flex items-center justify-center mt-10 text-center py-6 ">
                <span className="mx-2 text-2xl font-semibold text-black">
                Menu EV
                </span>
                    </div>

                    <Navigation
                    activeItemId={location.pathname}
                    onSelect={({itemId}) => {
                        navigate(itemId);
                    }}
                    items={[
                        {
                            title: "Home", itemId: "/admin/home", // Optional
                            elemBefore: () => <FaHome/>
                        }, {
                            title: "Inscriptions",
                            itemId: "/admin/inscriptions",
                            elemBefore: () => <GiArchiveRegister/>,
                            subNav: [{
                                title: "Indoor", itemId: "/admin/inscriptions/indoor", // Optional
                                elemBefore: () => <GiMountainClimbing/>
                            },{
                                title: "Outdoor", itemId: "/admin/inscriptions/outdoor", // Optional
                                elemBefore: () => <FaMountain/>
                            }
                                // ,{
                                //     title: "Outdoor",
                                //     itemId: "/inscriptions/outdoor",
                                //     elemBefore: () => <FaMountain/>
                                // }
                            ]
                        },
                        {
                            title: "Admin Panel", itemId: "/admin/panel",
                            elemBefore: () => <GrUserAdmin/>,
                            subNav: [
                                {
                                    title: "Cours", itemId: "/admin/indoor", // Optional
                                    elemBefore: () => <GiMountainClimbing color={'blue'}/>
                                },{
                                    title: "Stages", itemId: "/admin/indoorStage", // Optional
                                    elemBefore: () => <MdOutdoorGrill color={'blue'}/>
                                },{
                                    title: "Abonnements", itemId: "/admin/subscription", // Optional
                                    elemBefore: () => <FaPassport color={'blue'}/>
                                }, {
                                    title: "Team", itemId: "/admin/team-list", // Optional
                                    elemBefore: () => <AiOutlineTeam color={'blue'}/>
                                },
                                {
                                    title: "Comptes", itemId: "/admin/member-list",
                                    elemBefore: () => <RiTeamFill color={'blue'}/>,
                                },
                                {
                                    title: "Inscriptions", itemId: "/admin/inscriptions-list",
                                    elemBefore: () => <GiArchiveRegister color={'blue'}/>,
                                 },
                                {
                                     title:"Brevets", itemId: "/admin/brevets",
                                     elemBefore: () =><GiDiploma color={'blue'}/>
                                }

                            ]
                        },
                        {
                            title: "Team Panel", itemId: "admin/team-panel",
                            elemBefore: () => <RiTeamFill/>,
                            subNav: [{
                                title: "course List", itemId: "admin/team/course-list", // Optional
                                elemBefore: () => <BsListNested/>
                            }//,{
                            //   title:"Brevets", itemId: "admin/team/brevets",
                            //   elemBefore: () =><GiDiploma color={'black'}/>
                            // }
                            ]
                        }]
                    }
                />
                <div className="absolute bottom-0 w-full my-8">
                    {/*
                    <Navigation
                        activeItemId={location.pathname}
                        items={[
                            {
                                title: "Settings",
                                itemId: "/settings",
                                elemBefore: () => <GrUserSettings/>
                            }
                        ]}
                        onSelect={({itemId}) => {
                            navigate(itemId);
                        }}
                    />
                    */}
                    <div className="items-center justify-center  text-center  ">
                        <a className="text-black underline  hover:text-pinkEv transition duration-500 ease-in-out transform hover:-translate-y-1"
                           href={"https://www.ev-club.be/"}>retour à l'accueil du site</a>
                    </div>
                    <Disconnect/>
                </div>
            </div>
            }

        </React.Fragment>
    );
};
