import React, {Fragment, useState} from "react";
import Portal from "../Portal/Portal";
import {abortPayment, payment} from "../../services/PaymentService";
import {delete_inscription} from "../../services/InscriptionService"
import {BeatLoader} from "react-spinners";
import InfoIconWithTooltipRight from "../InfoIconWithTooltip/InfoIconWithTooltipRight";
import CountdownTimer from "../../components/TimerOrderModal/CountdownTimer";

const OrderModal = ({onCancel,orders, updateOrders}) => {

    const [error, setError] = useState();
    const [loader, setLoader] = useState(false);
    const [abortLoader, setAbortLoader] = useState(false);

    const handleSetError = (err) => {
        setError(err);
    }

    const handleTimerEnd = () => {
        onCancel();
        window.location.reload();
    };



    const handleDelInscription = (inscriptionId, courseId) => {
        delete_inscription(inscriptionId)
        updateOrders();
    }

    const handlePayment = async () => {
        try {
            setLoader(true)
            const response = await payment()
            if (response.status === 204) {
                updateOrders();
            }
        } catch (e) {
            setError(e)
        }
        setLoader(false)
    }

    const handleAbortPayment = async (orderId) => {
        try {
            setAbortLoader(true)
            const response = await abortPayment()
            if (response.status === 204) {
                onCancel();
                setAbortLoader(false)
            }
        } catch (e) {
            setError(e)
        }
        setAbortLoader(false)
    }


    if (orders && orders.order.status === 'PAYMENT_CREATED') {
        return (
            <Fragment>
                <div className="fixed inset-0 bg-black opacity-25 overflow-auto"/>
                <Portal>
                    <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0 overflow-auto"/>
                    <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-96 z-50 m-auto md:relative w-5/6">
                        <div className="md:flex items-center ">
                            <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                                {error &&
                                    <h1 className="rounded bg-red-600 w-60 m-auto ">{error}</h1>
                                }
                                <div className="   justify-center mt-3 w-full m-auto  overflow-x-auto relative ">
                                    {//add a message if the cart is empty in red
                                    }
                                    <p className="rounded text-red-600 font-bold w-60 m-auto ">Vous avez un payement en
                                        cours merci de le valider ou de l'annuler</p>
                                </div>
                                {//add button to leave the modal
                                }
                                <div className=" flex text-center  justify-center mt-3  ">
                                    <button
                                        className="text-center border-1 rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white"
                                        onClick={onCancel}>
                                        Retour
                                    </button>
                                    <button
                                        className=" ml-7 text-center border-1 rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white"
                                        onClick={() => {
                                            handlePayment()
                                        }}
                                    >
                                        Payer
                                        {loader &&
                                            <BeatLoader className="ml-2" color="#FFFFFF" size='10px'/>
                                        }
                                    </button>
                                    <button
                                        className=" ml-7 text-center border-1 rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white"
                                        onClick={() => {
                                            handleAbortPayment()
                                        }}
                                    >
                                        Annuler mon payement en cours
                                        {abortLoader &&
                                            <BeatLoader className="ml-2" color="#FFFFFF" size='10px'/>
                                        }
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            </Fragment>
        );
    } else if (orders && orders.inscriptionsCourses.length === 0 && orders.inscriptionsMembers.length === 0 && orders.inscriptionsTrainingCourses.length === 0 && orders.inscriptionsSubscriptions.length === 0) {
        return (
            <Fragment>
                <div className="fixed inset-0 bg-black opacity-25 overflow-auto"/>
                <Portal>
                    <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0 overflow-auto"/>
                    <div className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-96 z-50 m-auto md:relative w-5/6">
                        <div className="md:flex items-center ">
                            <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                                {error &&
                                    <h1 className="rounded bg-red-600 w-60 m-auto ">{error}</h1>
                                }
                                <div className="   justify-center mt-3 w-full m-auto  overflow-x-auto relative ">
                                    {//add a message if the cart is empty in red
                                    }
                                    <p className="rounded text-red-600 font-bold w-60 m-auto ">Votre panier est vide</p>

                                </div>
                                {//add button to leave the modal
                                }
                                <div className=" flex text-center  justify-center mt-3  ">
                                    <button
                                        className="text-center border-1 rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white"
                                        onClick={onCancel}>
                                        Retour
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Portal>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <div className="fixed inset-0 bg-black opacity-25 overflow-auto "/>
                <Portal>
                    <div onClick={onCancel} className="bg-black opacity-50  absolute z-10 inset-0"/>
                    <div
                        className="bg-white rounded-lg  p-4 fixed inset-x-0 bottom-60 z-50 m-auto md:relative w-5/6  overflow-auto overscroll-auto ">
                        <div className="md:flex items-center">
                            <div className="md:mt-0 md:ml-6 text-center md:text-left">
                                <div className="relative bg-white p-6 ">
                                    <CountdownTimer data={orders} onTimerEnd={handleTimerEnd}/>
                                </div>
                                    {error &&
                                        <h1 className="rounded bg-red-600 w-60 m-auto ">{error}</h1>
                                    }
                                    <p className="font-bold">Panier</p>
                                </div>
                            </div>

                            <div className="   justify-center mt-3 w-full m-auto  relative  ">
                            <table className="min-w-full border ">
                                <thead>
                                <tr className=" border ">
                                    <th className="p-1 text-left font-bold">inscrit</th>
                                    <th className="p-1 text-left font-bold">lieux</th>
                                    <th className="p-1 text-left font-bold">cours/stage/cotisation</th>
                                    <th className="p-1 text-left font-bold">prix</th>
                                    <td className="p-1 text-left font-bold">réduction</td>
                                    <th className="p-1 text-left font-bold">horaire</th>
                                    <th className="p-1 text-left font-bold">date</th>
                                    <th className="p-1 text-left font-bold"/>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-300 ">
                                {orders.inscriptionsCourses.map(order => (
                                    <tr key={order.id} className="border ">
                                        <td className="p-1">{order.account.first_name}</td>
                                        <td className="p-1">{order.location.name}</td>
                                        <td className="p-1">{order.section.description}</td>
                                        <td className="p-1">{order.inscription.price - (order.inscription.price * order.inscription.reduction / 100)}€</td>
                                        <td className="p-1">{order.inscription.reduction}%</td>
                                        <td className="p-1">{order.course.week_day} de {order.course.begin_time.substring(0, 5)} à {order.course.end_time.substring(0, 5)}</td>
                                        <td className="p-1"> du {order.inscription.begin_date.substring(8, 10)}/{order.inscription.begin_date.substring(5, 7)}/{order.inscription.begin_date.substring(2, 4)} au {order.inscription.end_date.substring(8, 10)}/{order.inscription.end_date.substring(5, 7)}/{order.inscription.end_date.substring(2, 4)}</td>
                                        <td className="p-1">
                                            <button
                                                className="bg-red-600 border-1 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                                onClick={() => {
                                                    handleDelInscription(order.inscription.id)
                                                    onCancel()
                                                }}>
                                                Supprimer
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                {orders.inscriptionsTrainingCourses.map(order => (
                                    <tr key={order.id} className="border ">
                                        <td className="p-1 text-left">{order.account.first_name}</td>
                                        <td className="p-1 text-left">/</td>
                                        <td className="p-1 text-left">stage:{order.trainingCourse.description}</td>
                                        <td className="p-1 text-left">{order.inscription.price}€</td>
                                        <td className="p-1 text-left">/</td>
                                        <td className="p-1 text-left"></td>
                                        <td className="p-1 text-left"> du {order.inscription.begin_date.substring(8, 10)}/{order.inscription.begin_date.substring(5, 7)}/{order.inscription.begin_date.substring(2, 4)} au {order.inscription.end_date.substring(8, 10)}/{order.inscription.end_date.substring(5, 7)}/{order.inscription.end_date.substring(2, 4)}</td>
                                        <td className="p-1 text-left">
                                            <button
                                                className="bg-red-600 border-1 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                                onClick={() => {
                                                    handleDelInscription(order.inscription.id)
                                                    onCancel()
                                                }}>
                                                Supprimer
                                            </button>
                                        </td>
                                    </tr>

                                ))}
                                {orders.inscriptionsMembersCab.map(order => (
                                    <tr key={order.id} className="border ">
                                        <td className="p-1 text-left">{order.account.first_name}</td>
                                        <td className="p-1 text-left">/</td>
                                        <td className="p-1 text-left">{'cotisation cab'}</td>
                                        <td className="p-1 text-left">{order.inscription.price}€</td>
                                        <td className="p-1 text-left">/</td>
                                        <td className="p-1 text-left"></td>
                                        <td className="p-1 text-left"> du {order.inscription.begin_date.substring(8, 10)}/{order.inscription.begin_date.substring(5, 7)}/{order.inscription.begin_date.substring(2, 4)} au {order.inscription.end_date.substring(8, 10)}/{order.inscription.end_date.substring(5, 7)}/{order.inscription.end_date.substring(2, 4)}</td>
                                        <td className="p-1 text-left">
                                            <button
                                                className="bg-red-600 border-1 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                                onClick={() => {
                                                    handleDelInscription(order.inscription.id)
                                                    onCancel()
                                                }}>
                                                Supprimer
                                            </button>
                                        </td>
                                    </tr>

                                ))}
                                {orders.inscriptionsSubscriptions.map(order => (
                                    <tr key={order.id} className="border ">
                                        <td className="p-1 text-left">{order.account.first_name}</td>
                                        <td className="p-1 text-left">/</td>
                                        <td className="p-1 text-left">{'abonnement'}</td>
                                        <td className="p-1 text-left">{order.inscription.price}€</td>
                                        <td className="p-1 text-left">/</td>
                                        <td className="p-1 text-left"></td>
                                        <td className="p-1 text-left"> du {order.inscription.begin_date.substring(8, 10)}/{order.inscription.begin_date.substring(5, 7)}/{order.inscription.begin_date.substring(2, 4)} au {order.inscription.end_date.substring(8, 10)}/{order.inscription.end_date.substring(5, 7)}/{order.inscription.end_date.substring(2, 4)}</td>
                                        <td className="p-1 text-left">
                                            <button
                                                className="bg-red-600 border-1 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                                onClick={() => {
                                                    handleDelInscription(order.inscription.id)
                                                    onCancel()
                                                }}>
                                                Supprimer
                                            </button>
                                        </td>
                                    </tr>

                                ))}
                                {orders.inscriptionsMembers.map(order => (
                                    <tr key={order.id} className="border ">
                                        <td className="p-1 text-left">{order.account.first_name}</td>
                                        <td className="p-1 text-left">/</td>
                                        <td className="p-1 text-left">{order.memberType.name === "indoor23" ?
                                            order.memberType.name = " indoor 23+"
                                            :
                                            "cotisation " + order.memberType.name
                                        }<InfoIconWithTooltipRight
                                            info={<i className=" justify-center w-1/3">Lors de la première inscription
                                                une cotisation au club obligatoire est ajoutée dans votre
                                                panier. Cette cotisation vous donne droit à des avantages: <a
                                                    href='https://www.ev-club.be/devenir-membre-EV-CAB'>lien vers les
                                                    avantages</a>.</i>
                                            }/>
                                        </td>
                                        <td className="p-1 text-left">{order.inscription.price}€</td>
                                        <td className="p-1 text-left">/</td>
                                        <td className="p-1 text-left"></td>
                                        <td className="p-1 text-left"> du {order.inscription.begin_date.substring(8, 10)}/{order.inscription.begin_date.substring(5, 7)}/{order.inscription.begin_date.substring(2, 4)} au {order.inscription.end_date.substring(8, 10)}/{order.inscription.end_date.substring(5, 7)}/{order.inscription.end_date.substring(2, 4)}</td>
                                        <td className="p-1 text-left">
                                            <button
                                                className="bg-red-600 border-1 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                                onClick={() => {
                                                    handleDelInscription(order.inscription.id)
                                                    onCancel()
                                                }}>
                                                Supprimer
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='flex flex-col'>
                            <div className= 'mt-2'>
                                <p className='text-center bg-red-600 border-1 text-white font-bold rounded w-auto '>Nous n'acceptons que les paiements par bancontact, les paiements par cartes sont refusés</p>
                            </div>


                            <div className=" flex text-center  justify-center   ">
                                <button
                                    className=" ml-7 mr-7 text-center border rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white"
                                    onClick={onCancel}>
                                    Retour
                                </button>
                                <p className="mt-2"><b>TOTAL:{orders.order.total_price} € </b></p>

                                <button
                                    className=" ml-7 text-center border-1 rounded p-1 border-pinkEv  m-1 transition duration-500 ease-in-out transform hover:-translate-y-1 bg-pinkEv text-white "
                                    onClick={() => {
                                        handlePayment()
                                    }}
                                >
                                    Payer
                                    {loader &&
                                        <BeatLoader className="ml-2" color="#FFFFFF" size='10px'/>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </Portal>
            </Fragment>
        );
    }

};

export default OrderModal;
