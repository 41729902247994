import React, {useContext, useEffect, useState} from "react";
import {DashboardLayout} from "../../components/Layout/Layout";
import CartContext from "../../contexts/CartContext";
import * as userApi from "../../services/AccountService";
import * as stageApi from "../../services/StageService";
import * as apiInscription from "../../services/InscriptionService";
import Select from "react-select";
import SelectorStage from "../../components/InscriptionsComponents/StageForms/SelectorStage";
import {ifExpiredDisconnect} from "../../utils/toolbox";
import {Checkbox, FormControlLabel} from "@mui/material";
import {pink} from "@mui/material/colors";
import {useNavigate} from "react-router-dom";
import {verifyCanRegister} from "../../services/InscriptionDatesService";


const Outdoors = () => {
    //only display a category if there some in the array

    const [categories, setCategories] = useState([])
    const [selectedAccount, setSelectedAccount] = useState();
    const [childList, setChildList] = useState([]);
    const [stageList, setStageList] = useState([])
    const [selectedOption, setSelectedOption] = useState();
    const [filteredStage, setFilteredStage] = useState([]);
    const [selectedStage, setSelectedStage] = useState()
    const [outdoorStageList, setOutdoorStageList] = useState([])
    const [selectedIndoorStageList, setSelectorOutdoorStageList] = useState([])
    const [account, setAccount] = useState([]);
    const [childAndAccountSelect, setChildAndAccountSelect] = useState([]);
    const [update, setUpdate] = useState(false);
    const [error, setError] = useState();
    const [isACabMember, setIsACabMember] = useState(false);
    const {updateCart} = useContext(CartContext);
    const navigate = useNavigate();


    useEffect(() => {
        let tab = []
        ifExpiredDisconnect()
        const Users = async () => {
            let children = await userApi.getCurrentUserChildrens()
            let account = await userApi.getCurrentAccount()
            setChildList(children);
            setAccount(account);
            children.forEach(child => {
                tab.push({value: child.id, label: child.first_name + " " + child.last_name})
            })
            tab.push({value: account.id, label: account.first_name + " " + account.last_name})
            setChildAndAccountSelect(tab);
        }

        const categoriesStage = async () => {
            let categories = await stageApi.stageCategories()
            setCategories(categories)
        }

        const stages = async () => {
            let stages = await stageApi.stageList()
            setStageList(stages)
            let indoorStages = stages.filter(stage => stage.type === "outdoor")
            setOutdoorStageList(indoorStages)
            let selectedIndoorStageList = indoorStages.map(stage => {
                return {value: "" + stage.id, label: stage.description}
            })
            setSelectorOutdoorStageList(selectedIndoorStageList)
        }
        stages();
        categoriesStage();
        Users();

        async function loader() {
            const valueRegister= await verifyCanRegister();
            if(valueRegister!==1){
                const currentPath = window.location.pathname;
                const pathParts = currentPath.split('/');
                const pop = pathParts.pop();
                if(pop==="outdoor"){
                    const parentPath = pathParts.join('/');
                    navigate(parentPath);
                }
            }
        }
        loader()
    }, [update,navigate])

    const handleSelectAccount = (e) => {
        setSelectedAccount(e)
    }

    const handleReset = () => {
        setSelectedAccount(null)
        setSelectedOption(null)
        onUpdate()
    }

    const handleSelect = (e) => {
        setSelectedAccount(null)
        setSelectedOption(e.target.textContent)
        //get the id of the selectedoption in the category list
        let id = categories.find(category => category.name === e.target.textContent).id
        //filter the stages by category id
        let stages = stageList.filter(stage => stage.category_id === id && stage.type === "outdoor")
        //format stages for selector
        let selectedStageList = stages.map(stage => {
            return {value: stage.id, label: stage.description}
        })
        setFilteredStage(selectedStageList)
    }


    const onUpdate = () => {
        setUpdate(!update)
    }

    const handleSelectedStage = (stage) => {
        setSelectedStage(stage)
        onUpdate()
    }
    const handleAddToCart = async (stageID) => {
        try {
            //add the course to the cart
            const res = await apiInscription.inscription_stage(stageID, selectedAccount.value);
            onUpdate();
            updateCart();
        } catch (e) {
            if (e.response.data.message === "Cannot register account to the course, order is in payment process") {
                setError("Vous avez un payment en cours,\n merci de le finaliser ou annuler avant de commencer un autre panier")
            } else {
                setError(e.response.data.message)
            }
        }

    }
    if (selectedOption === null || selectedOption === undefined) {
        return (<DashboardLayout>
            <h2 className="absolute -top-8 left-10 font-sans  text-xl">Outdoor Page</h2>
            <div className="flex h-screen overflow-y-auto ">
                {categories.length > 0 ?
                    <div className=" desktop:flex tablet:flex-wrap  bg-gray-700  m-auto  rounded-lg  w-max">
                        <div>
                            {categories.map(category => {
                                return (<button
                                    key={category.id}
                                    className=" m-3 border-black p-3 bg-gray-500  rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv"
                                    onClick={handleSelect}
                                >
                                    {category.name}
                                </button>)
                            })}
                        </div>
                    </div> : <div
                        className='desktop:flex tablet:flex-wrap  m-auto  rounded-lg  w-max  text-2xl font-bold text-red-600 '>
                        <p>Aucuns stages disponible</p>
                    </div>}
            </div>
        </DashboardLayout>)
    } else {
        return (<DashboardLayout>
            <h2 className="absolute -top-8 left-10 font-sans  text-xl">Outdoor</h2>
            <div className="flex-wrap ">
                <div className=" desktop:flex tablet:flex-wrap  bg-gray-700  m-auto rounded-lg p-5 mt-10 w-max">
                    {categories.map(category => {
                        if (selectedOption === category.name) {
                            return (
                                <div><button
                                key={category.id}
                                className=" m-3 border-black p-3 bg-pinkEv  rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-gray-500"
                                onClick={handleSelect}
                            >
                                {category.name}
                                </button></div>)
                        } else {
                            return (<div><button
                                key={category.id}
                                className=" m-3 border-black p-3 bg-gray-500  rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:bg-pinkEv"
                                onClick={handleSelect}
                            >
                                {category.name}
                            </button></div>)
                        }

                    })}
                </div>
                <div>
                    {selectedOption && <div className="flex flex-col items-center justify-center m-5">
                        <h1 className="text-3xl font-bold mb-2">Veuillez sélectionner un compte</h1>
                        <div className="flex flex-col items-center justify-center ">
                            <div className=" h-screen w-full">
                                <Select className="text-black " options={childAndAccountSelect}
                                        value={selectedAccount || ''}
                                        onChange={(e) => {
                                            handleSelectAccount(e)
                                        }}/>
                                {selectedAccount && <div>
                                    {filteredStage.length === 0 ? <div>
                                        <h3 className="mt-5 font-bold text-red-500"> Il n'y a pas de
                                            stages actuellement disponible
                                            pour {selectedOption}  </h3>
                                    </div> : <div>
                                        <div className="flex  flex-column
                                                 justify-center mt-5">
                                            <div>
                                                <FormControlLabel
                                                    value="end"
                                                    control={<Checkbox sx={{
                                                        color: pink[500], '&.Mui-checked': {
                                                            color: pink[500],
                                                        },
                                                    }}/>}
                                                    onChange={(e) => setIsACabMember(!isACabMember)}
                                                    label="Je suis déjà membre du Club Alpin Belge"
                                                    labelPlacement="end"

                                                />
                                            </div>
                                        </div>
                                        {isACabMember ? <div>
                                                <SelectorStage
                                                    selectedStage={selectedStage}
                                                    selectedAccount={selectedAccount}
                                                    selectedStageList={filteredStage}
                                                    handleSelectedStage={handleSelectedStage}
                                                    handleAddToCart={handleAddToCart}
                                                    onUpdate={onUpdate}
                                                    error={error}
                                                    setError={setError}
                                                />
                                            </div>
                                            :
                                            <div>
                                                <p>Vous devez être membre CAB outdoor afin de pouvoir participer à nos activitées outdoor.</p>
                                                <p>pour rejoindre le CAB c'est ici: <a href='https://www.clubalpin.be/adherez'>Lien vers le club alpin</a></p>
                                            </div>
                                        }
                                    </div>}
                                </div>}
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        </DashboardLayout>);
    }


}
export default Outdoors;
